import { Client } from '@actassa/api';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { defer, Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { SetClient } from 'state/root-state/actions/set-client';
import { RootState } from 'state/root-state/root.state';

import { ListComponent } from './components/list/list.component';
import { SelectClientService } from './select-client.service';

@Component({
    selector: 'shared-select-client',
    templateUrl: './select-client.component.html',
    styleUrls: ['./select-client.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        SelectClientService,
    ],
})
export class SelectClientComponent {
    @Select(RootState.client$) public client$: Observable<Client | null>;

    @Input() public disabled = false;

    constructor(
        private readonly selectClientService: SelectClientService,
        private readonly modalController: ModalController,
    ) { }

    public presentModal(): void {
        if (this.disabled) {
            return;
        }

        this.selectClientService.getClients$()
            .pipe(
                switchMap((clients) => defer(() => this.showModal(clients))),
                take(1),
            )
            .subscribe();
    }

    private async showModal(clients: Array<Client>): Promise<void> {
        const modal = await this.modalController.create({
            component: ListComponent,
            componentProps: {
                clients,
            },
            cssClass: 'modal',
            swipeToClose: true,
        });

        modal.onWillDismiss()
            .then(({ data }) => {
                if (data) {
                    this.setClient(data);
                }
            });

        return await modal.present();
    }

    @Dispatch()
    private setClient(data: Client): SetClient {
        return new SetClient(data);
    }
}
