import { Client } from '@actassa/api';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
    selector: 'outperform-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent {
    @Input() public clients: Array<Client> = [];

    constructor(
        private readonly modalController: ModalController,
    ) { }

    public select(data: Client): void {
        this.modalController.dismiss({ ...data });
    }

    public cancel(): void {
        this.modalController.dismiss();
    }
}
