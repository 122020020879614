// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import versionConfig from '../../../../package.json';

export const environment = {
    production: false,
    apiURL: 'https://ttmdev.actassa.com/mobile-dev',
    botURL: 'wss://bots.activeassociate.com/dev/webchat001/',
    firebase: {
        apiKey: 'AIzaSyD3YEQfvv8w3mwEu-zD8bjxyaqUYeZeqVw',
        authDomain: 'ttm-healthcare.firebaseapp.com',
        projectId: 'ttm-healthcare',
        storageBucket: 'ttm-healthcare.appspot.com',
        messagingSenderId: '553005949141',
        appId: '1:553005949141:web:f4adce1ec1958f25a0c25c',
        measurementId: 'G-9TF12RD3TJ',
    },
    socketIoConfig: {
        url: 'https://ttmdev.actassa.com:3006',
        options: {
            autoConnect: true,
        },
    },
    appVersion: versionConfig.version,
    appId: 'com.ttmhealthcare.ttmhealthcare',
    appName: 'TTM Healthcare',
    appleAppId: '1543990033',
    platform: 'web',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
