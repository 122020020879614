<ion-header translucent>
    <ion-toolbar>
        <ion-title>Select Client</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cancel()">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
    <ion-list>
        <ion-item *ngFor="let client of clients" (click)="select(client)">
            <ion-label>
                <h2>{{ client.name }}</h2>
            </ion-label>
        </ion-item>
    </ion-list>
</ion-content>