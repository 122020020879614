import {
    ApplicationsEnum,
    AuthTokenInterface, Client, DEFAULT_ROOT_STATE_DATA, FcmMessageDataInterface,
    MobileApplicationConfig,
    RootStateInterface,
    SendDeviceTokenInterface,
    UserAgencyMapInterface,
    UserInterface,
} from '@actassa/api';
import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';

import { AppStateActive } from './actions/app-state-active';
import { AppStateBackground } from './actions/app-state-background';
import { ClearAwaitingDeviceToken } from './actions/clear-awaiting-device-token';
import { ClearNotificationData } from './actions/clear-notification-data';
import { LoadingEnd } from './actions/loading-end';
import { LoadingStart } from './actions/loading-start';
import { LoginSuccess } from './actions/login-success';
import { Logout } from './actions/logout';
import { ResetState } from './actions/reset-state';
import { SelectRole } from './actions/select-role';
import { SendDeviceToken } from './actions/send-device-token';
import { SetAppSettings } from './actions/set-app-settings';
import { SetAwaitingDeviceToken } from './actions/set-awaiting-device-token';
import { SetClient } from './actions/set-client';
import { SetDebugMode } from './actions/set-debug-mode';
import { SetDeviceID } from './actions/set-device-id';
import { SetDeviceToken } from './actions/set-device-token';
import { SetNotificationData } from './actions/set-notification-data';
import { SetTimezone } from './actions/set-timezone';
import { UpdateRedirectURL } from './actions/update-redirect-url';

@State<RootStateInterface>({
    name: 'root',
    defaults: DEFAULT_ROOT_STATE_DATA,
})
@Injectable()
export class RootState {
    @Selector()
    public static isAuthenticated$(state: RootStateInterface): boolean {
        return Boolean(state.token);
    }

    @Selector()
    public static redirectURL$(state: RootStateInterface): string | null {
        return state.redirectURL;
    }

    @Selector()
    public static token$(state: RootStateInterface): AuthTokenInterface | null {
        const { token, deviceID } = state;

        return { token, deviceID };
    }

    @Selector()
    public static isDebugMode$(state: RootStateInterface): boolean {
        return state.isDebugMode;
    }

    @Selector()
    public static user$(state: RootStateInterface): UserInterface | null {
        return state.user;
    }

    @Selector()
    public static appSettings$(state: RootStateInterface): Partial<MobileApplicationConfig> | null {
        return state.appSettings;
    }

    @Selector()
    public static awaitingDeviceToken$(state: RootStateInterface): SendDeviceTokenInterface | null {
        return state.awaitingDeviceToken;
    }

    @Selector()
    public static auths$(state: RootStateInterface): Array<UserAgencyMapInterface> {
        return state.auths;
    }

    @Selector()
    public static app$(state: RootStateInterface): ApplicationsEnum {
        return state.app;
    }

    @Selector()
    public static providerId$(state: RootStateInterface): string {
        return state.providerID;
    }

    @Selector()
    public static isLoading$(state: RootStateInterface): boolean {
        return state.isLoading;
    }

    @Selector()
    public static notificationData$(state: RootStateInterface): FcmMessageDataInterface | null {
        return state.notificationData;
    }

    @Selector()
    public static deviceID$(state: RootStateInterface): string {
        return state.deviceID;
    }

    @Selector()
    public static deviceType$(state: RootStateInterface): string {
        return state.deviceType;
    }

    @Selector()
    public static timezone$(state: RootStateInterface): string {
        return state.timezone;
    }

    @Selector()
    public static isAppStateActive$(state: RootStateInterface): boolean {
        return state.isAppStateActive;
    }

    @Selector()
    public static client$({ client }: RootStateInterface): Client {
        return client;
    }

    @Action(AppStateActive)
    public setAppStateActive(stateContext: StateContext<RootStateInterface>): void {
        stateContext.patchState({ isAppStateActive: true });
    }

    @Action(AppStateBackground)
    public setAppStateBackground(stateContext: StateContext<RootStateInterface>): void {
        stateContext.patchState({ isAppStateActive: false });
    }

    @Action(UpdateRedirectURL)
    public updateRedirectURL(stateContext: StateContext<RootStateInterface>, action: UpdateRedirectURL): void {
        stateContext.patchState({
            redirectURL: action.redirectURL,
        });
    }

    @Action(ResetState)
    public resetState(stateContext: StateContext<RootStateInterface>): void {
        stateContext.setState(DEFAULT_ROOT_STATE_DATA);
    }

    @Action(LoginSuccess)
    public loginSuccess$(stateContext: StateContext<RootStateInterface>, { payload }: LoginSuccess): void {
        const {
            apps,
            auths,
            email,
            firstName,
            id,
            lastName,
            token,
            uuid,
        } = payload;

        stateContext.patchState({
            auths: [...(auths ? auths : [])],
            token,
            user: {
                email,
                firstName,
                id,
                lastName,
                uuid,
            },
        });
    }

    @Action(Logout)
    public logout(stateContext: StateContext<RootStateInterface>): void {
        const { deviceID } = stateContext.getState();

        stateContext.setState(DEFAULT_ROOT_STATE_DATA);
        stateContext.patchState({ deviceID });
    }

    @Action(SetDeviceToken)
    public setDeviceToken(stateContext: StateContext<RootStateInterface>, action: SetDeviceToken): void {
        const { deviceToken, deviceType } = action;

        stateContext.patchState({
            deviceToken,
            deviceType,
        });

        stateContext.dispatch(new SendDeviceToken({ deviceToken, deviceType }));
    }

    @Action(SetAppSettings)
    public setAppSettings(stateContext: StateContext<RootStateInterface>, { payload }: SetAppSettings): void {
        stateContext.patchState({ appSettings: payload });
    }

    @Action(SetDebugMode)
    public setDebugMode(stateContext: StateContext<RootStateInterface>, { isDebugMode }: SetDebugMode): void {
        stateContext.patchState({ isDebugMode });
    }

    @Action(SetAwaitingDeviceToken)
    public setAwaitingDeviceToken(stateContext: StateContext<RootStateInterface>, { payload }: SetAwaitingDeviceToken): void {
        stateContext.patchState({ awaitingDeviceToken: { ...payload } });
    }

    @Action(ClearAwaitingDeviceToken)
    public clearAwaitingDeviceToken(stateContext: StateContext<RootStateInterface>): void {
        stateContext.patchState({ awaitingDeviceToken: null });
    }

    @Action(LoadingStart)
    public loadingStart(stateContext: StateContext<RootStateInterface>): void {
        stateContext.patchState({ isLoading: true });
    }

    @Action(LoadingEnd)
    public loadingEnd(stateContext: StateContext<RootStateInterface>): void {
        stateContext.patchState({ isLoading: false });
    }

    @Action(ClearNotificationData)
    public clearNotificationData(stateContext: StateContext<RootStateInterface>): void {
        stateContext.patchState({ notificationData: null });
    }

    @Action(SetNotificationData)
    public setNotificationData(stateContext: StateContext<RootStateInterface>, { payload }: SetNotificationData): void {
        stateContext.patchState({ notificationData: { ...payload } });
    }

    @Action(SetDeviceID)
    public setDeviceID(stateContext: StateContext<RootStateInterface>, { deviceID }: SetDeviceID): void {
        stateContext.patchState({ deviceID });
    }

    @Action(SetTimezone)
    public setTimezone(stateContext: StateContext<RootStateInterface>, { timezone }: SetTimezone): void {
        stateContext.patchState({ timezone });
    }

    @Action(SelectRole)
    public selectRole(stateContext: StateContext<RootStateInterface>, { config }: SelectRole): void {
        const { user } = stateContext.getState();

        stateContext.patchState({
            app: config.role,
            providerID: config.providerID,
            user: {
                ...user,
                id: config.id,
                uuid: config.uuid,
                consultantID: config.consultantID,
                consultantEmail: config.consultantEmail,
            },
        });
    }

    @Action(SetClient)
    public setClient(stateContext: StateContext<RootStateInterface>, { client }: SetClient): void {
        stateContext.patchState({ client });
    }
}
