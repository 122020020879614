import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { ListComponent } from './components/list/list.component';
import { SelectClientRoutingModule } from './select-client-routing.module';
import { SelectClientComponent } from './select-client.component';

@NgModule({
    declarations: [
        SelectClientComponent,
        ListComponent,
    ],
    imports: [
        CommonModule,
        SelectClientRoutingModule,
        IonicModule,
    ],
    exports: [
        SelectClientComponent,
    ],
})
export class SelectClientComponentModule { }
